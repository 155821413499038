@import url("https://fonts.googleapis.com/css2?family=Sora:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
    --font-sora: "Sora", sans-serif;
    --font-code: "Source Code Pro", monospace;
    --font-grotesk: "Space Grotesk", sans-serif;
    --background-color: #121212;
    --text-color: #ffffff;
    --nav-text: #ffffff;
    --accent-color: #076EAB;
}

[data-theme='light']{
  --background-color: #cfcbcb;
  --text-color: #000000;
  --nav-text: #000000;
  --accent-color: #076EAB;
}


/* custom-scrollbar.css */

::-webkit-scrollbar {
  width: 10px; 
}

::-webkit-scrollbar-track {
  background: #f1f1f179; 
}

::-webkit-scrollbar-thumb {
  background-color: #000000; 
  border-radius: 10px; 
  border: 3px solid #a1a1a1; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

*{
  scroll-behavior: smooth;
}

 html,body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    color: var(--text-color);
    transition: background-color 0.3s, color 0.3s;
}

.overflow-hidden {
  overflow: hidden;
}

.mt-10 {
  margin-top: 2.5rem;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.inline-flex {
  display: inline-flex;
}

.gap-12 {
  gap: 3rem;
}